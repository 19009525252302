import React from 'react'
import { Subheader, Text, Token, Box, BREAKPOINTS } from '@revolut/ui-kit'

import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { PageBody } from '@components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ESignatureTemplateEditor } from '@src/features/ESignatureTemplateEditor'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useValidationErrors } from '@src/features/ESignatureTemplateEditor/common'
import { PageActions } from '@components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'

export const TabUploadFile = () => {
  const { values, submit } = useLapeContext<DocumentsTemplatesInterface>()
  const errors = useValidationErrors(values)

  return (
    <PageBody maxWidthMd={BREAKPOINTS.lg}>
      <Box mb="s-32">
        <Subheader>
          <Subheader.Title>
            {values.file_name
              ? 'Configure the template'
              : 'Upload the template for your eSignature'}
          </Subheader.Title>
        </Subheader>
        <Text variant="caption" color={Token.color.greyTone50}>
          {values.file_name
            ? 'Add all fields and placeholders to your document'
            : 'This is the base file to be used to generate the signatures on'}
        </Text>
      </Box>
      <ESignatureTemplateEditor canUploadAnotherFile values={values} errors={errors} />
      <PageActions>
        <NewSaveButtonWithPopup
          onClick={() => {
            errors.reset()
            return submit()
          }}
          previewUrl={ROUTES.APPS.DOCUMENTS.TEMPLATES.LIST}
          errorHandler={errors.parse}
        />
      </PageActions>
    </PageBody>
  )
}
