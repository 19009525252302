import { Flex, HStack, Icon, Link, Text, TextButton, Token } from '@revolut/ui-kit'
import { getKpiPerformanceGraph } from '@src/api/kpis'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { ColoredPercent } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { PercentageWrapper } from '@src/components/CommonSC/TableStatistics'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { selectorKeys } from '@src/constants/api'
import {
  CellInsertParams,
  CellTypes,
  ColumnInterface,
  FilterByInterface,
  FilterType,
} from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { EpicOption, KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import capitalize from 'lodash/capitalize'
import React, { ReactNode } from 'react'
import { fetchGoalGraph } from '@src/api/goals'
import { getStatusColor } from '@src/components/CommonSC/General'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { EditableColumnInterface } from '@src/components/Table/EditableTable/EditableTable'
import { roundFloat } from '@src/utils/numbers'
import { Statuses } from '@src/interfaces'
import { ROUTES } from '../routes'
import { pathToUrl } from '@src/utils/router'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
} from './kpi'

const isChildRow = (data: GoalsInterface | KpiInterface): data is KpiInterface => {
  return typeof data.update_type !== 'object'
}

export const goalsNameColumn = (
  filters: FilterByInterface[],
): ColumnInterface<GoalsInterface> => ({
  title: 'Goals & targets',
  type: CellTypes.text,
  dynamicHyperlinks: goal => {
    if (isChildRow(goal)) {
      return null
    }
    const route =
      goal.status.id === Statuses.draft
        ? ROUTES.FORMS.GOAL.EDIT
        : ROUTES.FORMS.GOAL.PREVIEW
    return pathToUrl(
      route,
      { id: goal.id },
      // filter allows to show switcher in the goal preview page no navigate between goals
      filterSortPageIntoQuery(undefined, filters) as Record<string, string>,
    )
  },
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'name',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.text,
  placeholder: (
    <Text color={Token.color.greyTone20}>
      <i>[Unnamed goal]</i>
    </Text>
  ),
})

export const goalsInitialValueColumn: ColumnInterface<GoalsInterface> = {
  title: 'Initial',
  type: CellTypes.insert,
  idPoint: 'initial_value',
  dataPoint: 'initial_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return data.initial_value
    }
    return ''
  },
}

export const goalsCurrentValueColumn: ColumnInterface<GoalsInterface> = {
  title: 'Current',
  type: CellTypes.insert,
  idPoint: 'current_progress',
  dataPoint: 'current_progress',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return data.current_progress
    }
    return ''
  },
}

export const goalsTargetColumns: ColumnInterface<GoalsInterface> = {
  title: 'Target',
  type: CellTypes.insert,
  idPoint: 'target',
  dataPoint: 'target',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return data.target
    }
    return ''
  },
}

export const goalsStrategyColumn: ColumnInterface<GoalsInterface> = {
  title: 'Strategy',
  type: CellTypes.insert,
  idPoint: 'kpi_goal',
  dataPoint: 'kpi_goal',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return capitalize(data.kpi_goal?.replaceAll('_', ' ') || '')
    }
    return ''
  },
}

export const goalsTypeColumn: ColumnInterface<GoalsInterface> = {
  title: 'Type',
  type: CellTypes.insert,
  idPoint: 'update_type',
  dataPoint: 'update_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      switch (data.update_type) {
        case UpdateTypes.manual:
          return <Icon name="Profile" size={18} />
        case UpdateTypes.sql:
          return <Icon name="ArrowRepeat" size={18} />
        case UpdateTypes.roadmap:
          return <Icon name="JiraEpic|image" size={18} />
        default:
          return capitalize(data.update_type)
      }
    }
    return ''
  },
}

export const goalsStatusColumn: ColumnInterface<GoalsInterface> = {
  title: 'Status',
  type: CellTypes.insert,
  idPoint: 'status__id',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.goal_statuses,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return ''
    }

    return <Text color={getStatusColor(data.status.id)}>{data.status.name}</Text>
  },
}

export const goalsApprovalStatusColumn: ColumnInterface<GoalsInterface> = {
  title: 'Approval status',
  type: CellTypes.insert,
  idPoint: 'approval_status__id',
  dataPoint: 'approval_status',
  sortKey: 'approval_status',
  filterKey: 'approval_status',
  selectorsKey: selectorKeys.goal_approval_statuses,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return ''
    }

    return (
      <Text color={getStatusColor(data.approval_status.id)}>
        {data.approval_status.name}
      </Text>
    )
  },
}

export const goalsProgressColumn: ColumnInterface<GoalsInterface> = {
  title: 'Progress',
  type: CellTypes.insert,
  idPoint: 'performance',
  dataPoint: 'performance',
  sortKey: 'progress',
  filterKey: 'progress',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    const progress = isChildRow(data)
      ? data.performance
      : data.calibrated_progress ?? data.progress
    const fetchGraph = isChildRow(data) ? getKpiPerformanceGraph : fetchGoalGraph
    // todo: fix rendering graph icon
    return (
      <GraphIconChart id={data.id} fetchData={fetchGraph}>
        <Flex width={75} justifyContent="space-between">
          <PercentageWrapper>
            <ColoredPercent percent={progress * 100} />
            <Icon type="Graph" />
          </PercentageWrapper>
        </Flex>
      </GraphIconChart>
    )
  },
}

export const goalsOwnerColumn: ColumnInterface<GoalsInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner',
  dataPoint: 'owner',
  sortKey: 'owner__full_name',
  filterKey: 'owner_id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const goalsWeightColumn: ColumnInterface<GoalsInterface> = {
  title: 'Weight',
  type: CellTypes.insert,
  idPoint: 'weight',
  dataPoint: 'weight',
  sortKey: 'weight',
  filterKey: 'weight',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return ''
    }
    return typeof data.weight !== 'undefined' ? `${roundFloat(data.weight, 2)}%` : ''
  },
}

export const goalsInputWeightColumn: EditableColumnInterface<GoalsInterface> = {
  inputType: TableCellInputType.positiveFloat,
  suffix: '%',
  title: 'Weight',
  idPoint: 'weight',
  dataPoint: 'weight',
  sortKey: 'weight',
  filterKey: 'weight',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  isEditable: data => !isChildRow(data),
  isHidden: data => isChildRow(data),
  cleanZeroOnFocus: true,
}

export const goalsUnitColumn: ColumnInterface<GoalsInterface> = {
  title: 'Unit',
  type: CellTypes.insert,
  idPoint: 'unit',
  dataPoint: 'unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return data.unit
    }
    return ''
  },
}

// goal target columnd

const isEpic = (row: KpiInterface | EpicOption): row is EpicOption => {
  return 'key' in row
}

const insertEmptyWhenEpic =
  (insertCb: (props: CellInsertParams<KpiInterface>) => ReactNode) =>
  (props: CellInsertParams<KpiInterface | EpicOption>) => {
    if (isEpic(props.data)) {
      return ''
    }

    return insertCb(props as CellInsertParams<KpiInterface>)
  }

export const goalTargetReviewCycleColumn: ColumnInterface<KpiInterface | EpicOption> = {
  type: CellTypes.insert,
  idPoint: 'review_cycle.cycle_id',
  dataPoint: 'review_cycle',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isEpic(data)) {
      return ''
    }
    // only one target can be inside goal kpi
    const target =
      (data.targets && data.targets[0]) || (data.target_epics && data.target_epics[0])

    if (target?.employee_cycle) {
      return target.employee_cycle.name
    }

    return target?.review_cycle?.name || ' - '
  },
  title: 'Cycle',
}

export const goalTargetUpdateTypeColumn = {
  ...kpiUpdateTypeColumn,
  title: 'Type',
  sortKey: null,
  filterKey: null,
} as ColumnInterface<KpiInterface | EpicOption>

export const goalTargetNameColumn = {
  ...kpiGenericNameColumn,
  title: 'Name',
  sortKey: null,
  filterKey: null,
  dynamicHyperlinks: data => {
    if (isEpic(data)) {
      return null
    }
    return kpiGenericNameColumn.dynamicHyperlinks!(data)
  },
  insert: props => {
    const { data } = props
    if (isEpic(data)) {
      return (
        <HStack space="s-12">
          <Link href={data.url} target="__blank">
            {data.key}:{' '}
          </Link>
          <Text>{data.name}</Text>
        </HStack>
      )
    }
    return kpiGenericNameColumn.insert!(props as CellInsertParams<KpiInterface>)
  },
} as ColumnInterface<KpiInterface | EpicOption>

export const goalTargetPerformanceColumn = {
  ...kpiPerformanceColumn,
  sortKey: null,
  filterKey: null,
  insert: insertEmptyWhenEpic(kpiPerformanceColumn.insert!),
} as ColumnInterface<KpiInterface | EpicOption>

export const goalTargetInitialValueColumn = {
  ...kpiInitialValueColumn,
  sortKey: null,
  filterKey: null,
  textAlign: 'left',
  insert: insertEmptyWhenEpic(kpiInitialValueColumn.insert!),
} as ColumnInterface<KpiInterface | EpicOption>

export const goalTargetCurrentValueColumn = {
  ...kpiCurrentValueColumn,
  sortKey: null,
  filterKey: null,
  textAlign: 'left',
  insert: insertEmptyWhenEpic(kpiCurrentValueColumn.insert!),
} as ColumnInterface<KpiInterface | EpicOption>

export const goalTargetTargetValueColumn = {
  ...kpiTargetColumn,
  sortKey: null,
  filterKey: null,
  textAlign: 'left',
  insert: insertEmptyWhenEpic(kpiTargetColumn.insert!),
} as ColumnInterface<KpiInterface | EpicOption>

export const goalTargetUnitColumn = {
  ...kpiUnitColumn,
  type: CellTypes.insert,
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => {
    if (isEpic(data)) {
      return ''
    }
    return data[kpiUnitColumn.dataPoint as keyof KpiInterface]
  },
} as ColumnInterface<KpiInterface | EpicOption>

export const goalTargetActionsColumn = ({
  onSelect,
  onDelete,
  pendingDeleteId,
}: {
  onSelect?: (kpi: KpiInterface) => void
  onDelete: (kpi: KpiInterface) => void
  pendingDeleteId?: number
}): ColumnInterface<KpiInterface | EpicOption> => ({
  type: CellTypes.insert,
  idPoint: 'actions_column',
  dataPoint: 'actions_column',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  textAlign: 'right',
  insert: ({ data }) => {
    if (isEpic(data)) {
      return ''
    }

    return data.update_type === UpdateTypes.cascaded ? null : (
      <Flex gap="s-8" justifyContent="flex-end">
        {onSelect && (
          <TextButton onClick={() => onSelect(data)}>
            <Icon name="Pencil" size={16} color={Token.color.greyTone50} />
          </TextButton>
        )}
        <TextButton
          disabled={pendingDeleteId !== undefined}
          onClick={() => onDelete(data)}
        >
          <Icon
            name={pendingDeleteId === data.id ? 'Loading' : 'Delete'}
            size={16}
            color={Token.color.greyTone50}
          />
        </TextButton>
      </Flex>
    )
  },
  title: '',
})
