import { ActionButton, Banner, HStack, InputGroup, Spacer, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { GoalUpdateType, GoalsInterface } from '@src/interfaces/goals'
import { selectUser } from '@src/store/auth/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CellWithItem } from '../common/CellWithItem'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import {
  AnalyticsDashboardInterface,
  GenericAnalyticsDashboardInterface,
} from '@src/interfaces/analyticsDashboards'
import { TargetsWidget } from './Widgets/Targets/TargetsWidget'
import { RoadmapsWidget } from './Widgets/Roadmaps/RoadmapsWidget'
import { DashboardsWidget } from './Widgets/Dashboards/DashboardsWidget'
import { OrgUnitSelector } from './OrgUnitSelector'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { ParentGoalField } from '../common/ParentGoalField'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import AddGoalTargetForm from './SidebarForms/AddGoalTargetForm'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { IdAndName } from '@src/interfaces'
import { LinkRoadmapsForm } from './SidebarForms/LinkRoadmapsForm'
import { RoadmapInterface, UnassignedRoadmapInterface } from '@src/interfaces/roadmaps'
import { addGoalRoadmap, roadmapsRequests } from '@src/api/roadmaps'
import { captureException } from '@sentry/core'
import { useSubmitFlowHelpers } from '../common/utils'
import { useTable } from '@src/components/Table/hooks'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { AddDashboardForm } from './SidebarForms/AddDashboardForm'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getAnalyticsDashboards, updateDashboard } from '@src/api/analyticsDashboards'
import SideBar from '@src/components/SideBar/SideBar'

type RightSideContent =
  | {
      type: 'target'
      item: Partial<KpiInterface>
    }
  | {
      type: 'roadmap'
    }
  | {
      type: 'dashboard'
      item?: GenericAnalyticsDashboardInterface
    }
  | null

export const GoalForm = ({
  enforceParentKpi,
  defaultReviewCycle,
}: {
  enforceParentKpi: boolean
  defaultReviewCycle?: ReviewCyclesInterface
}) => {
  const user = useSelector(selectUser) as unknown as EmployeeOptionInterface
  const { values } = useLapeContext<GoalsInterface>()
  const [parentKpiRequired, setParentKpiRequired] = useState(enforceParentKpi)
  const { getEntityProps } = useOrgEntity()

  const roadmapsTable = useTable<RoadmapInterface>(
    roadmapsRequests,
    [{ columnName: 'goal__id', filters: [{ id: values.id, name: values.name }] }],
    [{ sortBy: 'review_cycle__offset', direction: SORT_DIRECTION.DESC }],
    { disableQuery: true },
  )

  const dashboardsTable = useTable<GenericAnalyticsDashboardInterface>(
    {
      getItems: getAnalyticsDashboards('goal', values.id),
    },
    [],
    [],
    { disableQuery: true },
  )

  useEffect(() => {
    values.roadmaps = roadmapsTable.data
  }, [roadmapsTable.data])

  useEffect(() => {
    values.dashboards = dashboardsTable.data as AnalyticsDashboardInterface[]
  }, [dashboardsTable.data])

  const emptyTarget = {
    ...getEntityProps(),
    owner: values.owner as EmployeeOptionInterface & { team: IdAndName },
  }
  const [rightSide, setRightSide] = useState<RightSideContent>(null)

  useEffect(() => {
    if (values.update_type?.id === 'cascaded') {
      setParentKpiRequired(true)
    } else if (parentKpiRequired !== enforceParentKpi) {
      setParentKpiRequired(enforceParentKpi)
    }
  }, [values.update_type?.id])

  useEffect(() => {
    if (!values.owner) {
      values.owner = user
    }
    if (!values.description) {
      values.description = `Summary of what this goal measures (context):
How will it be measured (formula & unit):
The business goal this goal supports:
Any risks linked with achieving this goal:
`
    }
    if (!values.update_type) {
      values.update_type = { id: 'target_based', name: 'Target based' }
    }
  }, [])

  const syncUpdateType = (type?: UpdateTypes) => {
    const updateForm = (goalType: GoalUpdateType) => {
      if (values.update_type?.id !== goalType) {
        values.update_type = { id: goalType, name: goalType }
      }
    }
    switch (type) {
      case UpdateTypes.cascaded:
        updateForm('cascaded')
        break
      case UpdateTypes.aggregated:
        updateForm('aggregated')
        break
      default:
        updateForm('target_based')
    }
  }

  const withScrollTop = (cb: () => void) => {
    cb()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <TwoColumnsLayout
      leftFlex={4}
      rightFlex={3}
      left={
        <>
          <CellWithItem
            icon="Target"
            title="Define your goal"
            description="What is the outcome you want to achieve?"
          >
            <InputGroup data-testid="goal-form-general">
              <LapeNewInput name="name" label="Goal name" required />
              <HStack gap="s-16">
                <LapeRadioSelectInput
                  selector={selectorKeys.employee}
                  name="owner"
                  label="Owner"
                  required
                />
                <OrgUnitSelector />
              </HStack>
              {values.is_company ? null : (
                <ParentGoalField
                  contentType={values.content_type?.model}
                  required={parentKpiRequired}
                  value={values.parent}
                  onChange={parent => {
                    values.parent = parent
                  }}
                />
              )}
              <LapeNewTextArea
                name="description"
                label="Description and definition of done"
                required
                rows={3}
              />
            </InputGroup>
          </CellWithItem>
          <TargetsWidget
            viewMode={values.update_type?.id !== 'target_based'}
            onSelected={kpi =>
              withScrollTop(() =>
                setRightSide({
                  type: 'target',
                  item: kpi,
                }),
              )
            }
            onAdded={() => {
              withScrollTop(() =>
                setRightSide({ type: 'target', item: { ...emptyTarget } }),
              )
            }}
          />
          <RoadmapsWidget
            onAdded={() => withScrollTop(() => setRightSide({ type: 'roadmap' }))}
          />
          {values.content_type?.model !== 'employees' ? (
            <DashboardsWidget
              onAdd={() => withScrollTop(() => setRightSide({ type: 'dashboard' }))}
              onSelected={dash =>
                withScrollTop(() => setRightSide({ type: 'dashboard', item: dash }))
              }
            />
          ) : null}
        </>
      }
      right={
        rightSide?.type === 'target' ? (
          <AddTargetSide
            defaults={rightSide.item}
            reviewCycle={defaultReviewCycle}
            onTypeChanged={syncUpdateType}
            onAferAdded={() => setRightSide(null)}
          />
        ) : rightSide?.type === 'roadmap' ? (
          <AddRoadmapsSide
            reviewCycle={defaultReviewCycle}
            onAferAdded={() => {
              setRightSide(null)
              roadmapsTable.refresh()
            }}
          />
        ) : rightSide?.type === 'dashboard' ? (
          <AddDashboardSide
            dashboard={rightSide.item}
            onAferAdded={() => {
              setRightSide(null)
              dashboardsTable.refresh()
            }}
          />
        ) : null
      }
    />
  )
}

function AddTargetSide({
  defaults,
  reviewCycle,
  onTypeChanged,
  onAferAdded,
}: {
  defaults: Partial<KpiInterface>
  reviewCycle?: ReviewCyclesInterface
  onTypeChanged: (type?: UpdateTypes) => void
  onAferAdded: () => void
}) {
  const { values } = useLapeContext<GoalsInterface>()

  return (
    <SideBar
      variant="wide"
      onClose={onAferAdded}
      title={defaults.id ? 'Edit target' : 'Add target'}
    >
      <Banner>
        <Banner.Content>
          <Banner.Title>Set a target for your goal</Banner.Title>
          <Banner.Description>
            Select the performance cycle and the target type for tracking the progress
            towards your goal.
          </Banner.Description>
        </Banner.Content>
      </Banner>
      <Spacer height="s-16" />
      <AddGoalTargetForm
        onTypeChanged={onTypeChanged}
        goalId={values.id}
        owner={values.owner}
        onPatched={result => {
          if (values.kpis.find(({ id }) => id === result.id)) {
            values.kpis = values.kpis.map(item => (item.id === result.id ? result : item))
          } else {
            values.kpis.push(result)
          }
          onAferAdded()
        }}
        hideAggregated={
          !!values.kpis.length || values.content_type?.model === 'employees'
        }
        hideCascaded={!!values.kpis?.length || values.is_company}
        contentType={values.content_type?.model}
        onParentChanged={parent => {
          values.parent = parent
        }}
        initialCycle={reviewCycle}
        parent={values.parent}
        initialValues={defaults}
      />
    </SideBar>
  )
}

function AddRoadmapsSide({
  reviewCycle,
  onAferAdded,
}: {
  reviewCycle?: ReviewCyclesInterface
  onAferAdded: () => void
}) {
  const { values } = useLapeContext<GoalsInterface>()
  const { showError } = useSubmitFlowHelpers()
  const [pendingSubmit, setPendingSubmit] = useState(false)

  const submit = async (
    cycle: ReviewCyclesInterface,
    epics: UnassignedRoadmapInterface[],
  ): Promise<void> => {
    try {
      setPendingSubmit(true)
      if (values.is_company) {
        await addGoalRoadmap({
          keys: epics.map(epic => epic.key),
          review_cycle: cycle,
          goal: { id: values.id },
        })
      } else {
        const entityKey: 'employee' | 'team' | 'department' =
          values.content_type?.model === 'employees'
            ? 'employee'
            : values.content_type?.model === 'teams'
            ? 'team'
            : 'department'

        await addGoalRoadmap({
          keys: epics.map(epic => epic.key),
          review_cycle: cycle,
          goal: { id: values.id },
          [entityKey]: { id: values.object_id },
        })
      }
    } catch (err) {
      captureException(err)
      showError('Failed to add roadmap', 'Please, try again.')
    } finally {
      setPendingSubmit(false)
    }
  }

  return (
    <SideBar onClose={onAferAdded} title="Add roadmap" data-testid="roadmaps-form">
      <LinkRoadmapsForm
        pending={pendingSubmit}
        initialCycle={reviewCycle}
        ownerId={values.owner.id}
        submit={async ({ cycle, epics }) => {
          await submit(cycle, epics)
          onAferAdded()
        }}
      />
    </SideBar>
  )
}

function AddDashboardSide({
  onAferAdded,
  dashboard,
}: {
  onAferAdded: () => void
  dashboard?: GenericAnalyticsDashboardInterface
}) {
  const { values } = useLapeContext<GoalsInterface>()
  const { showError } = useSubmitFlowHelpers()
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const { navigateWithEntity, entity } = useOrgEntity()

  const submit = async ({
    id,
    related_goals,
  }: GenericAnalyticsDashboardInterface): Promise<void> => {
    try {
      setPendingSubmit(true)
      await updateDashboard({
        id,
        related_goals: [...(related_goals || []), { id: values.id, name: values.name }],
      })
      onAferAdded()
    } catch (err) {
      captureException(err)
      showError('Failed to link dashboard', 'Please, try again.')
    } finally {
      setPendingSubmit(false)
    }
  }

  const dashboardFormUrl = pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS, {})

  return (
    <SideBar
      onClose={onAferAdded}
      title={dashboard ? dashboard.name : 'Add dashboard'}
      subtitle={dashboard ? 'Linked dashboards details' : 'Link your dashboards'}
      data-testid="dashboard-form"
    >
      <VStack gap="s-16">
        <ActionButton
          useIcon="ArrowThinRight"
          onClick={() =>
            navigateWithEntity(dashboardFormUrl, {
              goalId: values.id,
              entityType: entity?.type,
              entityId: entity?.data.id,
              entityName: entity?.data.name,
            })
          }
        >
          Create new dashboard
        </ActionButton>
        <AddDashboardForm
          pending={pendingSubmit}
          initialDashboard={dashboard}
          onSave={submit}
        />
      </VStack>
    </SideBar>
  )
}
